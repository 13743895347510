import moment from "moment";
import { get, isEmpty } from "underscore";

import { store } from "../redux/store";
import { ROUTES } from "../Constants";
import { fileSizeExceeds } from "../helper/helper";

export const createQueryStringURL = (url, options) => {
  const queryString = Object.keys(options)
    .map((option) => `${option}=${options[option]}`)
    .join("&");
  return `${url}?${queryString}`;
};
export const renderTimestamp = (timestamp) => {
  let prefix = "";
  if (timestamp === "") return "a min ago";
  const timeDiff = Math.round(
    (new Date().getTime() - new Date(timestamp).getTime()) / 60000
  );
  if (timeDiff < 1) {
    // less than one minute ago
    prefix = "just now";
  } else if (timeDiff < 60 && timeDiff >= 1) {
    // less than sixty minutes ago
    prefix = `${timeDiff} min ago`;
  } else if (timeDiff < 24 * 60 && timeDiff >= 60) {
    // less than 24 hours
    prefix = `${Math.round(timeDiff / 60)} hours ago`;
  } else if (timeDiff < 31 * 24 * 60 && timeDiff > 24 * 60) {
    // less than 7 days
    prefix = `${Math.round(timeDiff / (60 * 24))} days ago`;
  } else if (timeDiff < 12 * 31 * 24 * 60 && timeDiff > 31 * 24 * 60) {
    // less than 12 month
    prefix = `${Math.round(timeDiff / (31 * 24 * 60))} months ago`;
  } else {
    // few month ago
    prefix = `few month ago`;
  }
  return prefix;
};
export const scrollToActiveConversation = (conversation_id) => {
  const element = document.getElementById(
    `chat_contact_list-interstride--${conversation_id}`
  );
  element && element.scrollIntoView({ behavior: "smooth", block: "center" });
};

export const openInNewTab = (url) => {
  window.open(url, "_blank", "noopener,noreferrer");
};
export const extractMessage = (data = {}) => {
  const {
    message,
    sender_id,
    sender_name,
    sender_image,
    shared_file,
    send_at,
    message_id,
    file_name,
    request_meeting,
    parent_message = {},
  } = data;
  return {
    message,
    sender_id,
    sender_name,
    sender_image,
    send_at,
    id: message_id,
    ...(file_name && { file_name }),
    ...(shared_file && { shared_file }),
    conversation_type: request_meeting ? "request_meeting" : "simple",
    parent_message,
  };
};

export const createFreshChatPreview = (data, conversation_id) => {
  const preview = {
    latest_message: "",
    conversation_id,
    title: data?.full_name || "",
    time: "",
    count: 0,
    user_image: data?.user_profile || null,
    receiver_id: data.id,
    user_deleted: false,
    mute_chat: false,
  };

  const conversation = {
    chat: {
      conversation_id,
      conversation_window_title: data?.full_name || "",
      mute_chat: false,
    },
    group_details: {},
    user_info: {
      ...data,
    },
    messages: [],
    current_page: 1,
    total_messsages: 0,
    total_pages: 1,
    next_page: null,
  };

  return {
    preview,
    conversation,
  };
};

export const generateDropdownFormattedData = (items) => {
  return items.map((item) => {
    return {
      value: item,
      label: item,
    };
  });
};

export const handleProfilePicCallback = (
  file,
  errorCallback,
  successCallback,
  applySizeLimitInMB = 5
) => {
  file.forEach((item) => {
    if (!["image/png", "image/jpg", "image/jpeg"].includes(item.type)) {
      errorCallback("error", "Please select a valid image (png, jpg, jpeg)");
    } else if (fileSizeExceeds(item.size, applySizeLimitInMB)) {
      errorCallback(
        "error",
        `Please select a file less than ${applySizeLimitInMB} MB`
      );
    } else {
      file.forEach((item) => {
        const reader = new FileReader();
        reader.readAsDataURL(item);
        reader.onload = () => {
          successCallback({
            file: reader.result,
            icon: URL.createObjectURL(item),
          });
        };
      });
    }
  });
};

export const handleGroupChatPicCallback = (
  file,
  errorCallback,
  successCallback,
  applySizeLimitInMB = 5
) => {
  file.forEach((item) => {
    if (!["image/png", "image/jpg", "image/jpeg"].includes(item.type)) {
      errorCallback("error", "Please select a valid image (png, jpg, jpeg)");
    } else if (fileSizeExceeds(item.size, applySizeLimitInMB)) {
      errorCallback(
        "error",
        `Please select a file less than ${applySizeLimitInMB} MB`
      );
    } else {
      file.forEach((item) => {
        successCallback({
          file: item,
          icon: URL.createObjectURL(item),
        });
      });
    }
  });
};

export const validateChatAttachment = (file) => {
  if (
    ![
      "image/png",
      "image/jpg",
      "image/jpeg",
      "video/mp4",
      "application/pdf",
      "application/msword",
      "text/csv",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/plain",
      "audio/mpeg",
    ].includes(file.type)
  ) {
    return "Please select a valid file (png, jpg, jpeg, video/mp4, pdf, doc)";
  }
  const size = parseFloat(file.size / (1024 * 1024)).toFixed(2);
  if (size > 5) {
    return "Can't attach file size more than 5 MB";
  }

  return 200; //ok
};

export const getAmbassadorActivity = (lastActive) => {
  const lastActiveBefore = lastActive ? moment().diff(lastActive, "days") : -1;

  return lastActiveBefore >= 0 && lastActiveBefore <= 7
    ? "Active"
    : lastActiveBefore > 7 && lastActiveBefore <= 30
    ? "Moderate"
    : "Inactive";
};

export const MULTI_MEDIA_TYPES = {
  IMAGE: "image",
  VIDEO: "video",
  PDF: "pdf",
  THUMBNAIL: "thumbnail",
};

export const MULTIMEDIA_CONFIG = {
  [MULTI_MEDIA_TYPES.IMAGE]: {
    types: ["image/jpg", "image/jpeg", "image/png", "image/gif"],
    size: 5,
    errorMsg: `Image {fileName} size should be < {size}MB`,
  },
  [MULTI_MEDIA_TYPES.VIDEO]: {
    types: [
      "video/mov",
      "video/mp4",
      "video/quicktime",
      "video/3gpp",
      "video/x-ms-wmv",
      "video/flv",
    ],
    size: 25,
    errorMsg: `Video {fileName} size should be < {size}MB`,
  },
  [MULTI_MEDIA_TYPES.PDF]: {
    types: ["application/pdf"],
    size: 10,
    errorMsg: `PDF {fileName} size should be < {size}MB`,
  },
  [MULTI_MEDIA_TYPES.THUMBNAIL]: {
    types: ["image/jpg", "image/jpeg", "image/png"],
    size: 5,
    errorMsg: `Thumbnail image {fileName} size should be < {size}MB`,
  },
};

export const getAcceptedTypes = (acceptedMedia) => {
  let types = [];
  acceptedMedia.forEach((multimediaType) => {
    types.push(MULTIMEDIA_CONFIG[multimediaType].types);
  });
  return types;
};

export const getFileNameValidation = (acceptedTypes) => {
  let fileExtensions = [];
  acceptedTypes
    .join()
    .split(",")
    .forEach((type) => {
      fileExtensions.push(type.split("/")?.[1] ?? "");
    });
  return fileExtensions.join(", ");
};

export const validateMultimedia = (file, acceptedMedia) => {
  let valid = false,
    accepted = false,
    errorMsg = "";

  acceptedMedia.forEach((multimediaType) => {
    const typeConfig = MULTIMEDIA_CONFIG[multimediaType];
    if (typeConfig.types.includes(file.type)) {
      valid = true;
      if (file.size / 1024 / 1024 > typeConfig.size) {
        errorMsg = typeConfig.errorMsg
          .replace("{fileName}", file.name)
          .replace("{size}", typeConfig.size);
      } else {
        accepted = true;
      }
      if (valid) return { valid, accepted, errorMsg };
    }
  });

  return { valid, accepted, errorMsg };
};

export const getFileMediaType = (type) => {
  const mediaTypes = Object.keys(MULTI_MEDIA_TYPES).map(
    (multimediaType) => MULTI_MEDIA_TYPES[multimediaType]
  );
  return (
    mediaTypes.find((multimediaType) =>
      MULTIMEDIA_CONFIG[multimediaType]?.types.includes(type)
    ) ?? "invalid"
  );
};

export const fetchRouteParamsFromURL = () => {
  const params = {};

  // Add current path of the URL if its any protected path after login to be accessed after login/signup
  const route_path = window.location.pathname;
  if (
    route_path !== ROUTES.BASE &&
    !route_path.includes(ROUTES.FORGOT_PASSWORD) &&
    !route_path.includes(ROUTES.RESET_PASSWORD) &&
    !route_path.includes(ROUTES.NOTIFICATION_UNSUBSCRIBE)
  ) {
    params.route_path = route_path;
    params.route_search = window.location.search;
  }

  console.log("Fetched saved route -> " + JSON.stringify(params));
  return params;
};

export const getRedirectRouteFromURL = () => {
  // Fetch saved routes from redux state
  let state = store.getState();
  const savedRoute = get(state, ["loginReducer", "savedRoute"], {});
  const selectedPortal = get(state, ["loginReducer", "selectedPortal"], {});

  const currentPath = window.location.pathname;
  const routeParams = savedRoute;
  const dashboardRoute =
    selectedPortal === "admission"
      ? ROUTES.ADMISSIONS_DASHBOARD
      : ROUTES.CAREER_DASHBOARD;
  let path = currentPath.includes(ROUTES.BASE)
    ? dashboardRoute
    : [
        ROUTES.RESET_PASSWORD,
        ROUTES.FORGOT_PASSWORD,
        ROUTES.NOTIFICATION_UNSUBSCRIBE,
      ].includes(currentPath)
    ? // If no saved route found then prioritize current route
      currentPath + window.location.search
    : dashboardRoute;

  if (routeParams && !isEmpty(routeParams)) {
    const { route_path = dashboardRoute, route_search = "" } = routeParams;
    path = route_path === "/" ? dashboardRoute : route_path + route_search;
  }
  return path;
};

export const getPreviousMonths = (monthsRequired = 12) => {
  let months = [];
  for (let i = monthsRequired - 1; i >= 0; i--) {
    months.push(moment().subtract(i, "months").format("MMM-YY"));
  }
  return months;
};

export const getBasePath = () => {
  const pathsWithParams = [
    "/admissions/stats/statistics/engagement-details",
    "/engage/marketing-kit/add",
    "/engage/marketing-kit",
  ];

  const currentPath = window.location.pathname;

  const pathWithoutParam = currentPath.slice(0, currentPath.lastIndexOf("/"));

  return pathsWithParams.includes(pathWithoutParam)
    ? pathWithoutParam
    : currentPath;
};

export const QUICK_REACTIONS_CHAT = [
  {
    emoji: "👍",
    unifiedWithoutSkinTone: "1f44d",
  },
  {
    emoji: "😃",
    unifiedWithoutSkinTone: "1f603",
  },
  {
    emoji: "🙏",
    unifiedWithoutSkinTone: "1f64f",
  },
  {
    emoji: "👎",
    unifiedWithoutSkinTone: "1f44e",
  },
];
