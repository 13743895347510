import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { contains, isEmpty, isUndefined } from "underscore";

import { getBasePath } from "../../utils/common";
import getDynamicDrawer from "../../components/Sidebar/sidebarData";
import { INTERSTRIDE_LOGO_WHITE, menuNames } from "../../Constants";
import { updateSelectedPortal } from "../../redux/action";
import { toastify } from "../../helper/helper";

import AngleDownArrow from "../../assets/svg/AngleDownArrow.svg";
import MenuCloseImg from "../../assets/svg/BackArrow.svg";
import EyeImg from "../../assets/svg/Eye.svg";
import MenuOpenImg from "../../assets/svg/ForwardArrow.svg";

let path = "";

const SideBar = (props) => {
  const {
    user = {},
    selectedPortal = "career",
    isSuperAdmin = false,
    isSchool = false,
    isSchoolAdmin = false,
    featurePermissions = {},
    selectedSchool = null,
    isGeneralAdvisor = false,
    careerAdminAccess = false,
    admissionsAdminAccess = false,
  } = useSelector((state) => state.loginReducer);

  const dispatch = useDispatch();

  const admin_permission = user?.admin_permission || {};
  const admission_admin_permission = user?.admission_admin_permission || {};

  const selectedSchoolToken = selectedSchool.token;
  const selectedSchoolId = selectedSchool.id;

  const [menuList, setMenuList] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [active, addActive] = useState(false);
  const [subMenu, setSubMenu] = useState(false);

  const basePath = getBasePath(); // Path without URL params for comparison
  const fullPath = window.location.pathname; // Path with URL params for final redirection

  const withoutSubMenu = ["TOPICS", "CHAT", "GROUPS", "Communities"];

  const withoutSubMenuPaths_StudentsAdmin = [
    "/dashboard",
    "/communication/topics",
    "/communication/add-topic",
    "/communication/manage-topic",
    "/my-profile",
    "/help-center",
    "/chat/messages",
    "/chat/add-message",
    "/chat/add-group",
  ];
  const withoutSubMenuPaths_AdmissionsAdmin = [
    "/admissions/dashboard",
    "/admissions/chat/topics",
    "/admissions/chat/add-topic",
    "/admissions/chat/manage-topic",
    "/my-profile",
    "/help-center",
    "/admissions/chat/messages",
    "/admissions/chat/add-message",
    "/admissions/chat/add-group",
  ];

  const setMenusAndDrawer = () => {
    const sidebarData = getDynamicDrawer({
      selectedPortal,
      admin_permission,
      admission_admin_permission,
      isSuperAdmin,
      isSchool,
      isSchoolAdmin,
      featurePermissions,
      user,
      isGeneralAdvisor,
      selectedSchoolId,
      isProduction: process.env.REACT_APP_ENV === "prod",
    });
    setMenuList(sidebarData);

    if (
      (selectedPortal === "career" &&
        !withoutSubMenuPaths_StudentsAdmin.includes(basePath)) ||
      (selectedPortal === "admission" &&
        !withoutSubMenuPaths_AdmissionsAdmin.includes(basePath))
    ) {
      let previousMenu = sidebarData.findIndex((menu) =>
        menu?.active_urls?.includes(basePath)
      );
      if (!isUndefined(previousMenu) && previousMenu >= 0) {
        if (
          basePath.includes("/appointment") &&
          sidebarData[previousMenu]?.active_urls.includes(basePath)
        ) {
          path = fullPath;
        } else {
          if (!isDrawerOpen) props.drawerOpenFn(true);
          setIsDrawerOpen(true);
          setActiveMenu(previousMenu);
          handleSubMenu(sidebarData[previousMenu]);

          const menu = sidebarData[previousMenu];

          if (menu.data && menu.data.length > 0) {
            const previousSubMenu = menu.data.findIndex(({ active_url = [] }) =>
              active_url.includes(basePath)
            );
            const nextMenuLevel = menu.data.findIndex(
              (menu) =>
                (menu?.active_url?.includes(basePath) ||
                  basePath === menu.url) &&
                menu?.data?.length > 0
            );
            if (!isUndefined(previousSubMenu)) {
              if (previousSubMenu >= 0) {
                path = basePath.includes(menu.data[previousSubMenu].url)
                  ? fullPath
                  : menu.data[previousSubMenu].url;
              }
              if (nextMenuLevel >= 0) {
                let nextData = menu.data[nextMenuLevel].data;
                const nextPath = nextData.findIndex(({ active_url = [] }) =>
                  active_url.includes(basePath)
                );
                if (!isUndefined(nextPath)) {
                  path = fullPath;
                  setSubMenu(subMenu ? false : menu.name);
                }
              }
            } else {
              if (menu.data[0].isDivider) path = menu.data[1].url;
              else path = menu.data[0].url;
            }
          }
          if (!path.includes(basePath)) {
            toastify("error", "You are not authorized to access this URL.");
          }
          setRedirect(true);
        }
      }
    }
    if (basePath.includes("dashboard") && isGeneralAdvisor) {
      path = "/appointment";
      setRedirect(true);
    }
  };

  useEffect(() => {
    if (window?.innerWidth <= 990) props.drawerOpenFn(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSelectedPortalForRoute = () => {
    // Check and update selected portal if current path doesn't belong to current selected portal
    const { allowedCareerPaths = [], allowedAdmissionsPaths = [] } =
      getDynamicDrawer({
        admin_permission,
        admission_admin_permission,
        isSuperAdmin,
        isSchool,
        isSchoolAdmin,
        isGeneralAdvisor,
        featurePermissions,
        returnPaths: true,
      });

    const COMMON_PATHS = [
      "/my-profile",
      "/notification",
      "/notification-settings",
      "/help-center",
    ];
    if (basePath === "/") {
      path =
        selectedPortal === "admission" ? "/admissions/dashboard" : "dashboard";
      setRedirect(true);
    } else if (!COMMON_PATHS.includes(basePath)) {
      // Check and set portal based on allowed paths and current path
      if (
        selectedPortal === "career" &&
        !["/dashboard", ...allowedCareerPaths].includes(basePath)
      ) {
        if (admissionsAdminAccess) {
          runSelectedPortalEffect.current = false;
          dispatch(updateSelectedPortal("admission"));
          return;
        } else {
          toastify("error", "You are not authorized to access this URL.");
          path = "/dashboard";
          setRedirect(true);
        }
      } else if (
        selectedPortal === "admission" &&
        !["/admissions/dashboard", ...allowedAdmissionsPaths].includes(basePath)
      ) {
        if (careerAdminAccess) {
          runSelectedPortalEffect.current = false;
          dispatch(updateSelectedPortal("career"));
          return;
        } else {
          toastify("error", "You are not authorized to access this URL.");
          path = "/admissions/dashboard";
          setRedirect(true);
        }
      }
    }

    setMenusAndDrawer();
  };

  const runSelectedPortalEffect = useRef(true);
  const onFirstLoad = useRef(true);
  useEffect(() => {
    if (onFirstLoad.current) {
      onFirstLoad.current = false;
      // Need to verify current selected portal and school for current route
      setSelectedPortalForRoute();
    } else if (!runSelectedPortalEffect.current) {
      setMenusAndDrawer();
      runSelectedPortalEffect.current = true;
    } else if (runSelectedPortalEffect) {
      path =
        selectedPortal === "admission" ? "/admissions/dashboard" : "/dashboard";
      setRedirect(true);
      props.drawerOpenFn(false);
      setIsDrawerOpen(false);
      setMenusAndDrawer(path);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPortal]);

  useEffect(() => {
    if (isSchoolAdmin && !isEmpty(featurePermissions))
      setMenusAndDrawer(
        selectedPortal === "admission" ? "/admissions/dashboard" : "/dashboard"
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featurePermissions]);

  useEffect(() => {
    if (redirect) {
      setRedirect(false);
    }
  }, [redirect]);

  const handleSubMenu = (menu) => {
    if (menu.data && menu.data.length > 0) {
      const menuWithSubmenu = menu.data.find(
        ({ data = [] }) => data && data.length > 0
      );
      if (menuWithSubmenu) setSubMenu(menu.name);
    }
  };

  return (
    <>
      {redirect && (
        <Redirect
          to={{
            pathname: path,
            // Need to preserve query strings for current path
            search: path === fullPath ? window.location.search : "",
          }}
        />
      )}

      <div className={`nav-sidebar`}>
        <div className="nav-header">
          <a
            href
            onClick={(e) => {
              e.preventDefault();
              if (isGeneralAdvisor) {
                path = "/appointment";
              } else {
                path =
                  selectedPortal === "admission"
                    ? "/admissions/dashboard"
                    : "/dashboard";
              }
              setRedirect(true);
              props.drawerOpenFn(false);
              setIsDrawerOpen(false);
            }}
          >
            <img src={INTERSTRIDE_LOGO_WHITE} alt={"Interstride"} />
          </a>
        </div>
        <div className={`navmenu`}>
          <div className="left-menu">
            <div className="left-ulWrap">
              <ul className="left-ul">
                {menuList.map((menu, index) => (
                  <li
                    key={index}
                    className={`${
                      isDrawerOpen && activeMenu === index ? "active" : ""
                    } ${menu.hideOnSidebar ? "d-none" : ""}`}
                  >
                    {!withoutSubMenu.includes(menu.name) ? (
                      <Tooltip title={menu.title || ""} disableInteractive>
                        <a
                          href
                          className={
                            contains(
                              menu.active_urls,
                              basePath + window.location.search
                            )
                              ? "active left-main-menu"
                              : "left-main-menu"
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (
                              menu.name === menuNames.MEET &&
                              !admin_permission["advisors_users"] &&
                              admin_permission["appointment"]
                            ) {
                              if (isDrawerOpen) props.drawerOpenFn(false);
                              setIsDrawerOpen(false);
                              setActiveMenu(index);
                              handleSubMenu(menu);
                            } else {
                              if (!isDrawerOpen) props.drawerOpenFn(true);
                              setIsDrawerOpen(true);
                              setActiveMenu(index);
                              handleSubMenu(menu);
                            }
                            if (menu.name === menuNames.ENGAGE)
                              path = menu.active_urls[0];
                            else if (menu.data[0].isDivider)
                              path = menu.data[1].url;
                            else path = menu.data[0].url;
                            setRedirect(true);
                          }}
                        >
                          <div className="icon-wrapper"> {menu.icon}</div>
                          <span>{menu.name}</span>
                        </a>
                      </Tooltip>
                    ) : (
                      <Tooltip title={menu.title} disableInteractive>
                        <div>
                          <a
                            href
                            className={
                              contains(
                                menu.active_urls,
                                basePath + window.location.search
                              )
                                ? "active left-main-menu"
                                : "left-main-menu"
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              path = menu.url;
                              setRedirect(true);
                              props.drawerOpenFn(false);
                              setIsDrawerOpen(false);
                            }}
                          >
                            <div className="icon-wrapper"> {menu.icon}</div>
                            <span>{menu.name}</span>
                          </a>
                        </div>
                      </Tooltip>
                    )}

                    <div className="right-menu">
                      {menu.name !== "POST" && <h3>{menu.name}</h3>}

                      {menu.data && menu.data?.length > 0 && (
                        <ul>
                          {menu.data.map((menu_data, menu_index) => (
                            <div key={menu_index}>
                              {menu_data.isDivider ? (
                                <h3
                                  key={menu_index}
                                  className={menu_index !== 0 ? "divider" : ""}
                                >
                                  {menu_data.title}
                                </h3>
                              ) : (
                                <li
                                  className={
                                    menu_data.data &&
                                    menu_data.data.length &&
                                    subMenu === menu_data.name
                                      ? "submenu open"
                                      : subMenu
                                      ? "submenu"
                                      : ""
                                  }
                                  key={menu_index}
                                >
                                  {menu_data.data && menu_data.data.length ? (
                                    <>
                                      <Tooltip
                                        title={menu_data.title || ""}
                                        disableInteractive
                                      >
                                        <a
                                          href
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setSubMenu(
                                              subMenu === menu_data.name
                                                ? false
                                                : menu_data.name
                                            );
                                          }}
                                          className={
                                            contains(
                                              menu_data.active_url,
                                              basePath
                                            )
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          {menu_data.name}
                                          <img
                                            src={AngleDownArrow}
                                            alt="expand"
                                          />
                                        </a>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <>
                                      <Tooltip
                                        title={menu_data.title || ""}
                                        disableInteractive
                                      >
                                        <a
                                          href
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            addActive(!active);
                                            path = menu_data.url;
                                            setRedirect(true);
                                          }}
                                          className={
                                            contains(
                                              menu_data.active_url,
                                              basePath
                                            )
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          {menu_data.name}
                                        </a>
                                      </Tooltip>
                                    </>
                                  )}

                                  {subMenu &&
                                  menu_data.data &&
                                  menu_data.data.length ? (
                                    <ul>
                                      {menu_data.data.map(
                                        (
                                          menu_sub_data,
                                          menu_sub_data_index
                                        ) => (
                                          <li key={menu_sub_data_index}>
                                            <Tooltip
                                              title={menu_sub_data.title || ""}
                                              disableInteractive
                                            >
                                              <a
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  path = menu_sub_data.url;
                                                  setRedirect(true);
                                                }}
                                                href
                                                className={
                                                  contains(
                                                    menu_sub_data.active_url,
                                                    fullPath
                                                  )
                                                    ? "active"
                                                    : ""
                                                }
                                              >
                                                {menu_sub_data.name}
                                              </a>
                                            </Tooltip>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              )}
                            </div>
                          ))}
                        </ul>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            {isGeneralAdvisor ? (
              <></>
            ) : (
              <ul className="left-ul bottom">
                <li
                  className={
                    (selectedPortal === "career" &&
                      withoutSubMenuPaths_StudentsAdmin.includes(basePath)) ||
                    (selectedPortal === "admission" &&
                      withoutSubMenuPaths_AdmissionsAdmin.includes(basePath))
                      ? "d-none"
                      : ""
                  }
                >
                  <Tooltip title="Collapse sidebar" disableInteractive>
                    <a
                      className="left-main-menu"
                      href
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        props.drawerOpenFn(!isDrawerOpen);
                        setIsDrawerOpen(!isDrawerOpen);
                      }}
                    >
                      <div className="icon-wrapper">
                        <img
                          src={isDrawerOpen ? MenuCloseImg : MenuOpenImg}
                          alt="collapse"
                        />
                      </div>
                      <span>{isDrawerOpen ? "CLOSE" : "OPEN"}</span>
                    </a>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip
                    title={
                      selectedPortal === "career"
                        ? "Go to Career Portal"
                        : "Go to Admissions Portal"
                    }
                    disableInteractive
                  >
                    <a
                      className="left-main-menu"
                      href={
                        selectedPortal === "career"
                          ? "https://student.interstride.com/"
                          : `https://admissions.interstride.com${
                              selectedSchoolToken
                                ? `?token=${selectedSchoolToken}`
                                : ""
                            }`
                      }
                      target="blank"
                    >
                      <div className="icon-wrapper">
                        <img src={EyeImg} alt="eye" />
                      </div>
                      <span>
                        {selectedPortal === "career"
                          ? "STUDENT VIEW"
                          : "PROSPECT VIEW"}
                      </span>
                    </a>
                  </Tooltip>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
